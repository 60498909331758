import React, { FC } from 'react';
import NextLink from 'next/link';
import { useTranslation } from "next-i18next";

import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LanguageIcon from '@mui/icons-material/Language';


interface IFooterProps {
  locale: string;
}

const Footer: FC<IFooterProps> = (props: IFooterProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const Copyright = () => (
    <>
      <Typography
        variant='body2'
      >
        {'Copyright © '}{process.env.appName}, {new Date().getFullYear()}{' '}
      </Typography>
      <noindex>
        <Stack direction='row'
          spacing={0.5}
          justifyContent='start'
          alignItems='center'
        >
          <Link aria-label='RU/EN' href={t('appUrl')} rel='nofollow noopener' color='inherit' variant='body2' title='RU/EN' >
            <LanguageIcon color='inherit' sx={{ fontSize: 12 }} />
          </Link>
          <NextLink href='/policy' passHref>
            <Link rel='nofollow' color='inherit' variant='body2' title='Политика конфиденциальности' >
              {t('policy')}
            </Link>
          </NextLink>
        </Stack>
        <Typography
          variant='body2'
          paragraph
        >
          {t('offer')}
        </Typography>
      </noindex>
    </>
  );


  return (
    <Box
      component='footer'
      sx={{
        p: 3,
        mt: 'auto',
        borderTop: `1px solid ${theme.palette.grey[800]}`,
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Stack direction='row'
        spacing={1}
        justifyContent='center'
        alignItems='center'
      >
        <Typography
          variant='body2'
        >
          {t('author')}
          {' • '}
          {' © '}
          {new Date().getFullYear()}
          {' • '}
          <NextLink href='/' passHref>
            <Link rel='nofollow' color='inherit' variant='body2' title={process.env.appName} >
              {process.env.appName}
            </Link>
          </NextLink>
        </Typography>
      </Stack>
      <Stack direction='row'
        spacing={1}
        justifyContent='center'
        alignItems='center'
      >
        <Typography
          variant='body2'
        >
          {t('offer')}
        </Typography>
      </Stack>
      <Stack direction='row'
        spacing={1}
        justifyContent='center'
        alignItems='center'
      >
        <NextLink href='/policy' passHref>
          <Link rel='nofollow' color='inherit' variant='body2' title={t('policy')} >
            {t('policy')}
          </Link>
        </NextLink>
      </Stack>
    </Box >
  );
}
export default Footer;