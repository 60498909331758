import React, { FC } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import NextLink from 'next/link';
import Link from '@mui/material/Link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import AppDrawer from '../drawer/AppDrawer';
import LanguageIcon from '@mui/icons-material/Language';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import ButtonGroup from '@mui/material/ButtonGroup';

interface INavBarProps {
  mainMenu: {
    name: string,
    slug: string,
    locale: string,
  }[]
}

const NavBar: FC<INavBarProps> = (props: INavBarProps) => {
  const theme = useTheme();
  const { locale, locales, defaultLocale, asPath } = useRouter();

  const { mainMenu } = props;


  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const lengButtons = locales?.map((l) => (
    <NextLink href='/' locale={l} key={l} passHref>
      <Button
        component={Link}
        disabled={l === locale ? true : false}
      >
        {l}
      </Button>
    </NextLink>
  ))


  return (
    <AppBar position='static' sx={{
      flexGrow: 1,
      background: theme.palette.background.default,
    }}>
      <Toolbar>
        <Box sx={{
          flexGrow: 1,
          justifyContent: 'flex-start',
          alignItems: 'center',
          display: 'flex',
        }}>
          <NextLink href={'/'} passHref>
            <Link
              sx={{ mt:1, textDecoration: 'none' }}
            >
              <Image
                loading='eager'
                src='/logo.svg'
                alt={process.env.appName}
                width='46'
                height='46'
              />
            </Link>
          </NextLink>
          {/* <NextLink href={'/'} passHref>
            <Link
              sx={{ cursor: 'pointer', fontSize: '2rem', fontStyle: 'italic', fontWeight: 'bold', my: '1rem', color: theme.palette.primary.main, textDecoration: 'none', ml: '1rem', display: { xs: 'none', sm: 'flex' } }}
            >
              {process.env.appName}
            </Link>
          </NextLink> */}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: { xs: 'flex', md: 'none' } }}>
          <AppDrawer pages={mainMenu} />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 0.6 }}>
          {mainMenu.map((page) => (
            <NextLink href={page.slug} locale={page.locale} key={page.name} passHref>
              <Button
                key={page.name}
                aria-label={page.name}
                LinkComponent={Link}
                variant='text'
                sx={{
                  color: `/${asPath.split('/')[1]}` === page.slug ? theme.palette.primary.main : theme.palette.primary.dark,
                  '&:hover': {
                    color: theme.palette.primary.main
                  }
                }}
              >
                {page.name}
              </Button>
            </NextLink>
          ))}
        </Box>
        <Box sx={{ justifyContent: 'flex-end', display: 'flex' }}>
          <IconButton
            size="large"
            aria-label="Сhoose site language"
            aria-controls="language-menu"
            aria-haspopup="true"
            title='Сhoose language'
            onClick={handleMenu}
            edge='start'
            sx={{
              color: theme.palette.primary.dark,
              '&:hover': {
                color: theme.palette.primary.main
              }
            }}
          >
            <LanguageIcon />
          </IconButton>
          <Menu
            id="language-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuList sx={{ px: 2 }}>
              <ButtonGroup size="small" aria-label="small button group">
                {lengButtons}
              </ButtonGroup>
            </MenuList>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default NavBar;