import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from "next-i18next";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import NavBar from '../navigates/NavBar';
import Footer from '../footer/Footer';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTheme } from '@mui/material/styles';



interface IMainLayoutProps {
  title: string,
  description: string,
  keywords: string,
  image?: string,
  canonical?: string,
  locale: string,
  children: React.ReactNode
};

export default function MainLayout(props: IMainLayoutProps) {
  const { children, title, description, keywords, locale, canonical } = props;
  const theme = useTheme();

  const { asPath, pathname } = useRouter();
  const { t } = useTranslation();
  const ogDImage = locale === 'en' ? '/icons/favicon-og-en.png' : '/icons/favicon-og-ru.png';
  const ogImage = props.image ? props.image : ogDImage;


  const mainMenu = [
    {
      name: t('mainMenu.about'),
      slug: `/${pathname === '/' ? '#' : ''}about`,
      locale
    },
    {
      name: t('mainMenu.contacts'),
      slug: `/${pathname === '/' ? '#' : ''}contacts`,
      locale
    },
    {
      name: t('mainMenu.posts'),
      slug: `/${pathname === '/' ? '#' : ''}posts`,
      locale
    },
  ];

  interface ScrollTopProps {
    querySelector: string;
    window?: () => Window;
    children: React.ReactElement;
  }

  const ScrollTop = (props: ScrollTopProps) => {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 100,
    });

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      const anchor = (
        (event.target as HTMLDivElement).ownerDocument || document
      ).querySelector(`#${props.querySelector}`);

      if (anchor) {
        anchor.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }



    return (
      <Zoom in={trigger}>
        <Box
          onClick={handleClick}
          role='presentation'
          sx={{ position: 'fixed', bottom: 50, right: 20 }}
        >
          {children}
        </Box>
      </Zoom>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Head>
        <title>{`${title} | ${process.env.appName}`}</title>
        {canonical ? <link rel='canonical' href={`${t('appUrl')}/${canonical}`} /> : undefined}
        <meta name='description' content={description} />
        <meta name='keywords' content={keywords} />
        <meta name='author' content='aelinkov.pro' />
        <meta name="msapplication-TileColor" content={theme.palette.primary.main} />
        <meta name="theme-color" content={theme.palette.background.default} />
        <meta name='apple-mobile-web-app-title' content={process.env.appName} />
        <meta name='application-name' content={process.env.appName} />
        <meta name='msapplication-TileColor' content={theme.palette.primary.main} />
        <meta property='og:type' content='website' />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        <meta property='og:url' content={t('appUrl') + asPath} />
        <meta property='og:locale' content={locale} />
        <meta property='og:profile:username' content={t('author')} />
        <meta property='og:profile:gender' content='male' />
        <meta property='og:image' content={t('appUrl') + ogImage} />
        <meta property='og:image:alt' content={title} />
      </Head>
      <NavBar mainMenu={mainMenu} />
      <div id='back-to-top-anchor' />
      <CssBaseline />
      <Box
        sx={{
          minHeight: '90vh',
        }}
      >
        {children}
      </Box>
      <ScrollTop querySelector={'back-to-top-anchor'}>
        <Fab color='primary' size='small' aria-label='scroll back to top'>
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
      <Footer {...props} />
    </Box>
  );
}